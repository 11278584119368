export const French = {
    navbar: {
        follow: "Suivez-nous sur",
        home: "Accueil",
        product: "Produits",
        about: "A propos",
        blog: "Blog",
        service: "Services",
        contact: "Contactez-nous",
        lang: "Langue",
        qualite: "Qualités",
        list_pro: {
            p1: "TUYAU EN ACIER",
           /*  p2: "ÉQUIPEMENT CONVENTIONNEL DE FORAGE ET DE RÉCUPÉRATION",
            p3: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p4: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT" */
            p2: "ÉQUIPEMENT CONVENTIONNEL DE FORAGE...",
            p3: "COMPLETE SETS OF THERMAL RECOVERY...",
            p4: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT"
        }
    },
    footer: {
        copyright: "Tout droits reservés",
        privacy: "Politique de confidentialité",
        location: "Quartier Ngouaboussi, CQ519, Pointe-noire, Congo.",
        Tubing: "Tubes OCTG",
        Casing: "Boîtier OCTG",
        Vaccum: "Tubes et tubages d’inluation sous vide",
        Anti: "Tubes anti-corrosion et anti-usure",
        Precision: "Tuyau de laminage de précision",
        list_pro: {
            p1: "TUYAU EN ACIER",
            p2: "ÉQUIPEMENT CONVENTIONNEL DE FORAGE ET DE RÉCUPÉRATION",
            p3: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p4: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT" 
        }
    },
    notfound: {
        no: "Page non trouvée",
        message: "Nous sommes désolés, la page que vous recherchez n’existe pas sur notre site ou est en cours de construction !",
        home: "Retourner à la page d'accueil"
    },
    contact: {
        conctact: "Contactez-nous",
        home: "Accueil",
        noclick: "Contact",
        message: "Si vous avez des questions, n’hésitez pas à nous contacter. Nous serions ravis de vous aider",
        send_message: "Laissez-nous un message",
        speciality: {
            message: "Nous sommes spécialisés dans",
            exp: "30+ ans d’expérience dans la fabrication",
            garanty: "Jusqu’à 20 ans de garantie de sortie",
            charge: "Prise en charge complète de vos besoins personnalisés",
            advantage: "Plus d’avantage de prix et de capacité de contrôle de la qualité",
            otg: "Fabricant de tubes et de boîtiers OCTG"
        },
        advantage: {
            message: "Pourquoi sommes-nous différents ?",
            OCTG: "Fournisseur de solutions OCTG",
            assurance: "Assurance qualité de classe",
            price: "Prix concurrentiel",
            flexible: "Flexibilité sur mesure",
            litle: "Petit atelier simple",
            quality: "Très mauvaise qualité",
            badprice: "Prix très cher",
            sell: "Forcer la vente à vous"
        },
        welcome: "Soyez les bienvenue, vous pouvez nous rendre visite à tout moment",
        location: "Quartier Ngouaboussi, CQ519, Pointe-noire, Congo",
        name: "Votre nom",
        email: "Votre adresse email",
        phone: "Téléphone format internationnal",
        object: "L'objet du message",
        req: "Saisir votre message",
        btn: "Envoyer"
    },
    about: {
        about: "Qui sommes-nous ?",
        home: "Accueil",
        noclick: "A propos",
        details: {
            p1: `Afriwell Group, une société congolaise, est spécialisée à promouvoir, soumissionner et négocier dans la vente 
            des Appareils conventionnels et hydrauliques de reconditionnement des puits capable de travailler sur 
            on/offshore, des équipements de forage et de complétion qui répondent aux exigences API & des produits 
            chimiques.`,
            p2: `Afriwell Group, SARL représente dans cette ligne trois sociétés fabricants des Appareils de work over, des 
            équipements de forage et complétion, des fittings dont le siège social opérationnel est en Chine & aux 
            USA(Houston),de distribution des produits chimiques et d’inspection des appareils de forage, 
            plateformes et raffinerie.`,
            p4: `Direction`,
            p5: `La Direction d'Afriwell Group est formée par une équipe expérimentée ,composée pour l’instant par son actuel 
            DG, Mr MABAYA BAVOUEZA Christian Audrey, Consultant en développement en affaire d’ingénierie de 
            forage & Maintenance, Polytechnicien ENSP Congo, Certifié IFP dans la conception ,la planification & la 
            construction Pratique du Puits de pétrole, MBA ESLSCA de Paris(France), Master en contrôle Gestion de 
            SUP VENTES Ile de France ,notre équipe expérimentée est bien formée, motivée et se cherche constamment 
            à se remettre en question pour trouver de meilleures façons d'exercer leurs activités et d'optimiser nos 
            processus pour mieux accompagner nos clients dans les sociétés pétrolières et gazières.`,
        },
        advantages: {
            message: "Pourquoi sommes-nous différents ?",
            OCTG: "Fournisseur de solutions OCTG",
            assurance: "Assurance qualité de classe",
            price: "Prix concurrentiel",
            flexible: "Flexibilité sur mesure",
            litle: "Petit atelier simple",
            quality: "Très mauvaise qualité",
            badprice: "Prix très cher",
            sell: "Forcer la vente à vous"
        },
        an: "Années d’expérience",
        year: "ans",
        exp: "Membres de l’équipe",
        customers: "Des clients satisfaits",
        projects: "Projets réalisés",
        partners: "Partenaires officiels",
        advantage: "Solution idéale avec quatre ans d’expérience",
        next: "Lire plus",
        prev: "Reduire",
        seo: "CEO & Fondateur",
        chooseus: "POURQUOI NOUS CHOISIR",
        people: "Pourquoi les gens nous font-ils confiance ?",
        contact: "Laissez Afriwell group soutenir votre entreprise, résoudre vos problèmes et réduire vos coûts",
        btn: "contactez-nous dès maintenant"
    },

    home: {
        contact: "Laissez Afriwell group soutenir votre entreprise, résoudre vos problèmes et réduire vos coûts",
        btn: "contactez-nous dès maintenant",
        carousel: {
            weare: "Bienvenue à Afriwell group",
            details: " agence exclusive de fabricant professionnel de tubes et de boîtiers OCTG, de forets"
        },
        /* fab: "Fabricant de confiance pour vous",
        ad: "Afriwell Group se distingue par son expertise dans la conception et la fabrication de tubes et de boîtiers OCTG de haute qualité, ainsi que par sa gamme variée de produits incluant des tubes et des boîtiers d'isolation sous vide, des tuyaux de laminage de précision, et des solutions de tubes anti-corrosion et anti-usure.",
        btier: "Tubes et boîtiers OCTG", */
        fab: "A propos",
        ad1: `Afriwell Group, une société congolaise, est spécialisée à promouvoir, soumissionner et négocier dans la vente 
        des Appareils conventionnels et hydrauliques de reconditionnement des puits capable de travailler sur 
        on/offshore, des équipements de forage et de complétion qui répondent aux exigences API & des produits 
        chimiques.`,
        ad2: `Afriwell Group, SARL représente dans cette ligne trois sociétés fabricants des Appareils de work over, des 
        équipements de forage et complétion, des fittings dont le siège social opérationnel est en Chine & aux 
        USA (Houston), de distribution des produits chimiques et d’inspection des appareils de forage, 
        plateformes et raffinerie.`,
        about: "Qui sommes-nous",
        btier: "Tubes et boîtiers OCTG",
        product: "Produits phares",
        list: {
            btn: "Voir plus Details",
            p1: {
                title: "Tubes OCTG",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p2: {
                title: "Boîtier OCTG",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p3: {
                title: "Tubulure d’inluation sous vide",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p4: {
                title: "Tuyau de laminage de précision",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p5: {
                title: "Tube plaqué tungstène",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p6: {
                title: "Tubes Polycore",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p7: {
                title: "Tube de revêtement HTPT",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            p8: {
                title: "Pipeline de surface de revêtement EXPE",
                p1: "100 000 tonnes par an",
                p2: "20 ans"
            },
            devis: "Demandez un devis",
            plus: "Voir plus de produits"
        },
        why: "Quelles sont les raisons pour lesquelles Afriwell Group exporte ses produits ?",
        years: "+ de 60 pays",
        reason: {
            p1: `Nos partenaires ont 50 ans d'expérience dans la fabrication d'équipements de forage et de récupération`,
            p2: `Nos partenaires ont une solution unique pour tous les produits de forage et de récupération.`,
            p3: `Nos partenaires disposent d'une certification complète et de solutions intégrées.`,
            p4: `Nos partenaires disposent de tubes et de boîtiers OCTG spéciaux qui peuvent résoudre efficacement les problèmes difficiles de la production des champs pétrolifères tels que la corrosion et l'usure des tubes de pétrole, le tartre du puits d'injection d'eau, la conservation de la chaleur lors de l'injection de vapeur.`,
            p5: `Nos partenaires ont plus de 30 ans d'expérience dans la fabrication de tubes et de boîtiers OCTG.`,
            p6: `Nos partenaires ont 20 ans d'expérience en matière de tubes et de boîtiers OCTG aux normes internationales.`,
            p7: `Nos partenaires ont plus de 20 ans d'expérience dans les tubes et boîtiers EUE OCTG.`,
            p8: `Nos partenaires ont OCTG Tubing & Casing qui a le meilleur rapport coût-performance en Chine et aux États-Unis.`
        },
    },

    qualite: {
        about: "Qualités",
        home: "Accueil",
        noclick: "Qualités",
        qualite: {
            title: "Comment nos partenaires controle-t-ils la qualité de la production ?",
            p1: `Laboratoires physique et chimique, mesure & précisions, Thermique-instrumentation, nous 
            menons des expériences de performances physiques, et chimiques sur chaque lot de matières 
            premières entrant dans l'usine.`,
            p2: `un système complet de gestion de la qualité conforme aux exigences de l’ISO 9001, API Q1 & 
            HSE certifiés par DNV , nous maintenons les procédures de contrôle qualité très strictes par nos 
            lab. mesure & précision, Thermique-instrumentation et suivi des certifications par compagnies 
            d’inspection`,
            p3: `Des Ateliers certifiés ayant des monogrammes API 5CT, API 11AX,API 6A, API 16A ,API 11E et 
            ASME S, U.`,
            p4: `Toute procédure pendant la production ou avant la livraison de l'usine, nous acceptons 
            l'inspection de tout institut tiers comme exigence du client. `,
            p5: `Orientés vers la Recherche & Développement dans oilfields`
        },
        confiance: {
            title: `POURQUOI FAUT IL NOUS FAIRE PLUS CONFIANCE EN DEHORS DU RESPECT DE NORMES 
            INTERNATIONALES?`,
            p1: {
                title: "NOTRE EXPERIENCE",
                p1: `+30 ans d’expérience la machination des Tubing & casing (API 5CT).`,
                p2: `10 ans d’expérience dans la production en ligne et développement des Precision-rolling .`,
                p3: `+30 ans d’expérience dans la conception & machination des unités de pompage(API 11E).`,
                p4: `+40 ans d’expérience dans la conception & machination des pompes de fond(API 11AX) 
                avec(CE).`,
                p5: `+40 d’expérience dans la conception & machination des tètes de puits & arbre de 
                noel(PR2)/API6A/API 16A.`,
                p6: `+30 ans d’expérience dans la conception & fabrication des Tanks,seprateurs(ASME U S).`,
                p7: `+25 ans experiences dans la conception et fabricationTertiary oil recovery system`
            },
            p2: {
                title: "ASSISTANCE CLIENT POUR LE CHOIX DES TUBING ,CASING & POMPES DE FONDS",
                p1: `Pour les puits de pétrole contenant du H2S, nous sélectionnons normalement le matériau de 
                qualité S ou SS, par exemple L80S et L80SS.`,
                p2: `Pour les puits de pétrole contenant du CO2, nous sélectionnons normalement le matériau ajouté 
                au chrome, par exemple L80-13Cr, ou une qualité supérieure.`,
                p3: `NB: avec des performances anticorrosion et anti-abrasifs`
            },
            p3: {
                title: "RESPECT DE LA POLITIQUE DE DEFAILLANCE DES TUBING & CASINGS VIT & VIC",
                p1: `La défaillance et la garantie des tubes et casings sous vide sont un indice très important pour les champs 
                pétrolifères et les clients, Lorsqu’ils demandent VIT et VIC, ils souhaitent souvent confirmer la politique 
                de défaillance et de garantie, Il exige selon la norme SY/T5324-2013 : pour l'ancien VIT après injection 
                de vapeur, lorsque sa conductivité thermique est supérieure à 0,1 W/ (m·℃), il sera considéré comme un 
                échec. `,
                p2: `Outre le problème de qualité, ce problème est principalement dû à l'environnement de travail, tel que les 
                puits à haute teneur en soufre où le corps du tubing et les soudures sont beaucoup plus corrodés que 
                dans les puits normaux`,
                p3: `Par conséquent, nous recommandons aux clients de fournir des informations, détaillées sur l’état des 
                puits. Selon les données des clients, nous pouvons concevoir des VIT spéciaux.`
            },
            p4: {
                title: "RESPECT DE LA POLITIQUE DE GARANTIE & DES STOCKS DES TUBING & CASING VIT & VIC",
                p1: `Généralement, dans des conditions de service et de stock régulières, nous pouvons allez à fournir une 
                garantie jusqu’à 12 mois pour les puits et de 24 mois pour le stock, Lorsqu'il est utilisé dans un puits 
                de stimulation à la vapeur, la durée de vie doit, être d'au moins 30 cycles de stimulation soit au moins 
                24 mois.`,
                p2: `Dans le cadre de la garantie, on modifiera gratuitement les produits défectueux pour le client après avoir 
                reçu un rapport de non-conformité formel d'un tiers en cas de défaillance causée par un problème de 
                qualité du produit.`,
                p3: `Hors garantie, on est également disposé à aider le client à résoudre le problème de défaillance du produit 
                si le client a des exigences, mais le coût sera à sa charge.`,
                p4: `Mais surtout, l'état de chaque puits est différent, comme le milieu de corrosion, le milieu d'injection, la 
                température du puits et la longueur du train de tubes ce qui affectera la défaillance et la garantie des 
                tubes et casing sous vide.`
            },
            p5: {
                title: "REDUIRE LE RISQUE DES DOMMAGES DANS LE TRANSPORT DES TUBINGS & CASINGS",
                p1: `Nous pouvons emballer les tubes selon les exigences du client sinon en bundles et chaque 
                bundle pèse au moins 2 tonnes.`,
                p2: `Nous assumons vos risques de chargement, déchargement et de transport en pratiquant des 
                prix en CIF & DAP(à partir de 5MU$D).`,
                p3: `Nous pouvons fournir aux clients les documents suivants : `,
                p4: `MTC (Mill Test Certificate) et autres documents de rapport d'inspection, y compris le 
                rapport hydrostatique, le rapport d'examen physique et chimique le rapport d'inspection 
                de filetage, etc., dans n'importe quelle version linguistique selon les exigences du client.`,
                p5: `TALLY SHEET : indiquant toutes les informations sur les tubes ou casings, y compris la 
                taille, le poids unitaire la longueur unitaire, la longueur totale, les paquets, la quantité/le 
                paquet.`,
                p6: `Nous fournissons tous les documents nécessaires pour le dédouanement de destination 
                et d’autres documents pour aider le client à obtenir la politique préférentielle du pays.`,
            },
            p6: {
                title: "TEMPS DE LIVRAISON ADAPTE AU RYTHME DU CLIENT",
                p1: `Réduction de la durée de fabrication (par un système de ¼)`,
                p2: `Prix compétitifs`,
                p3: `Présence des Ateliers backup en cas d’urgence `,
                p4: `Utilisation des stock mini disponible en chine & Singapour`,
                p5: `Affectation d’un supply boat propre au client et pour des commandes spéciales à partir de 
                30MU$D`,
                p6: `Service après ventes(en atelier puis chez le client)`
            }
        }
    },

    services: {
        about: "Nos Services",
        home: "Accueil",
        noclick: "services",
        services: {
            p1: {
                title: `ASSISTANCE TECHNIQUE, DEVELOPPEMENT D’AFFAIRE FORAGE & RECONDITIONNEMENT`,
                p1: `Ingénierie de forage & Maintenance`,
                p2: `Gestion des projets`,
                p3: `Audit & Survey des appareils de forage & plateformes`,
                p4: `Analyse & stratégique et développement d’affaires de forage et reconditionnement`,
                p5: `Développement du réseau de contact pour attirer des nouveaux clients`,
                p6: `A la quête des nouveaux challenges, suivi & développement des projets`,
                p7: `Faire des projections de ventes et prévoir les revenus, en fonction du Résultat budgétisé`
            },
            p2: {
                title: "GESTION ET OPÉRATIONS DES APPAREILS TERRESTRES ET PLATEFORMES",
                p1: `Notre expertise dans la gestion, l'entretien et l'exploitation de plates-formes on/offshore, allant des appareils 
                modulaires de reconditionnement aux opérations de mines terrestres et aux plates-formes de forage, est ce 
                qui fait d'AFRIWELLGROUP votre solution optimale pour vos travaux on/offshore sur des projets.
                Avec AFRIWELLGROUP, vos précieux actifs seront entre les mains des équipes les plus qualifiées et 
                dévouées, qui les préserveront et les exploiteront de la manière la plus efficace et la plus sûre`
            },
            p3: {
                title: `MAINTENANCE ET RÉACTIVATION DES APPAREILS DE FORAGE (TERRASSE & TRAVAUX SUR 
                    PLATEFORMES)`,
                p1: `Nous proposons des solutions personnalisées avec une efficacité inégalée, en appliquant notre expertise 
                Technique et nos solutions d'ingénierie pour modifier, reconcevoir, mettre à niveau et réactiver les plates formes terrestres et de forage offshore. Nous avons la solution pour votre projet.`
            },
            p4: {
                title: `CONSEILS POUR LOCATION ET SERVICE HPU 340K & 460K`,
                p1: `Nos HPU340K & 460K sont une alternative sûre et rentable aux plates-formes de reconditionnement 
                conventionnelles`,
                p2: `La Réduction de coûts est directement réalisée grâce aux temps de montée/descente rapides et de 
                MOB/De-MOB.`,
                p3: `Peut reconditionner un puits, remettre le puits en production et De-Mob avant qu'une plate-forme de 
                reconditionnement conventionnelle à grande échelle puisse même être installée sur une installation offshore 
                typique`,
                p4: `NOTRE PERFORMANCE UNITÉ DE TRACTION-PUITS POUR UN PUITS DE 1000m
                Installation (jours) : 3-5 avec test Bop, Installation (jours) : 2-3, Opérations de forage (jours) : 15-18, Déplacement de la plate-forme (jours) : 1-2 en fonction du déplacement rapide du navire.
                `
            },
            p5: {
                title: `INSPECTION & SURVEY`,
                p1: `Tanks /Séparateur/Lignes & tuyauteries`,
                p2: `Grue offshore suivant API 2D`,
                p3: `Drilling structures & Derrick Mast suivant API4G-CatIII & IV`,
                p4: `Corrosion & revetements`
            },
            p6: {
                title: `CONTROLE DE GESTION (FORAGE & RECONDITIONNEMENT)`,
                p1: `Démarche budgétaire, animation du Budget & contrôle de budgétaire`,
                p2: `Analyse de profitabilité, profit et contrôle des coûts et des marges`,
                p3: `Analyse & diagnostique de la situation financière`,
                p4: `Gestion des activités & de la performance`,
                p5: `Outils de prise de décision`,
                p6: `Contrôle des activités et des Risques`,
                p7: `conseils & accompagnement en Analyse & stratégie d’affaires`
            },
            p7: {
                title: `ASSISTANCE ET CONSEILS POUR L'UTILISATION DE LA MICRO-TECHNOLOGIE`,
                p1: `Souhaitez-vous augmenter votre production dans laquelle la perméabilité du réservoir Virgin peut vous 
                donner x2, x3 ou x4 de votre production actuelle de puits ? Si oui, qu'est-ce que cela signifierait ?
                Moins de puits requis pour atteindre votre plan de développement de champ et votre objectif de production. 
                Vous économisez 50 à 75 % sur les coûts de forage de développement ?
                Pour un nombre donné de puits prévus : vous obtenez 2 à 4 fois plus de production.
                Retour sur investissement (ROI) double, triple ou quadruple.`
            }
        }
    },
    products: {
        about: "Nos Produits",
        home: "Accueil",
        noclick: "Produits",
        list: {
            p0: "Tuyau en acier",
            p0_1: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p0_2: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT",
            p1: {
                title: "Tuyau en acier sans soudure",
                desc: `Tuyaux en acier au carbone 
                    sans soudure :
                    Diamètre extérieur : 13,72 mm 914,4 mm
                    Épaisseur de paroi : 1,25 mm 50 mm
                    Longueur : 5,8/6/11,8/12 m.
                    Tuyau en acier inoxydable sans 
                    soudure :
                    Diamètre extérieur : 18,0 mm 610 mm
                    Épaisseur de paroi : 1,6 mm 40 mm
                    Longueur : 5,8/6/11,8/12 m`
            },
            p2: {
                title: "Tuyau d’acier soudé",
                desc: `Tuyaux en acier pour restes explosifs de guerre :
                Diamètre extérieur : 21,3mm-660mm
                Épaisseur de paroi : 1mm à 20mm
                Longueur : 5,8/6/11,8/12m
                Tuyau en acier LSAW : Diamètre extérieur : 406mm-1626mm
                Épaisseur de paroi : 6,0mm à 60mm
                Longueur : 5,8/6/11,8/12m`
            },
            p3: {
                title: "Produits tubulaires pour champs",
                desc: `Boîtier API : Diamètre extérieur: 114,3mm-508mm
                Épaisseur de paroi : 5,21mm 22,22mm
                Longueur: R1/R2/R3 Tubes API : Diamètre extérieur : 40mm-115mm
                Épaisseur de paroi : 3,0mm-16mm Longueur: R1/R2/R3`
            },
            p4: {
                title: "Raccords de tuyauterie et brides",
                desc: `Coude de tuyau, coude de tuyau, té de tuyau, réducteur de tuyau, 
                capuchon de tuyau, bride de tuyau, etc. Raccords et brides en acier inoxydable :
                Coude de tuyau, coude de tuyau, té de tuyau, réducteur de tuyau, capuchon de tuyau, bride de tuya`
            },
            p5: "ÉQUIPEMENT CONVENTIONNEL DE FORAGE ET DE RÉCUPÉRATION",
            p6: "Tubes et boîtiers conventionnels",
            p7: {
                title: "Raccord tubing & carter :Tubing :1.9 à 4'1/2 boîtier :4’1/2 à 5’1/2",
                desc: `Les matériaux d'accouplement couvrent toute la gamme standard
                Précision dimensionnelle élevée. Bonne performance et anti-grippage`
            },
            p8: {
                title: "Tuyaux et tubes roulants précis (2 3/8 ~ 4 ½)",
                desc: `Qualité stable et fiable. Fabrication et tests selon la dernière version de l'API 5CT
                A été vendu aux États-Unis. Détective défectueux livraison à 100%`
            },
            p9: {
                title: "Boîtier (4 1/2 ~ 13 3/8)",
                desc: `Les parois intérieures et extérieures du tuyau ont une excellente rugosité de haute précision 
                (Ra=0,2-0,6). Les matériaux peuvent être modifiés pour répondre aux exigences des clients.
                Finition lisse des murs intérieurs et extérieurs. Capable de supporter une haute pression`
            },
            p10: "TÊTE DE PUITS ET ÉQUIPEMENT D'ARBRE X MAS",
            p11: {
                title: "Tête de puits et arbre de Noël",
                desc: `Connexions standards API 6A Etage séparé pour chaque phase de forage. Pression de service 2000-20000 si -600C à 3900C température de fonctionnement
                Cintres automatiques. Qualité fiable, installation et maintenance faciles. Huile, eau, gaz, boue
                Psl1 Psl2 Psl3 Psl3g Psl4 PR1PR2.`
            },
            p12: {
                title: "Tête de puits conventionnelle et arbre de Noël",
                desc: `Connexions bride/pince standard API 6A Installation et retrait faciles. Performances d'étanchéité fiables
                Longue durée de vie. Bonne apparence. Diam:2''9/16 WP : 3 000 à 5 000 psi -200C à 800C température de fonctionnement. Taille du boîtier de raccordement : 5’’1/2. Dimension(mm):1500x1190; 2268x1740`
            },
            p13: {
                title: "Tête de puits offshore et arbre de Noël",
                desc: `Vanne principale combinée et vanne à écouvillon pour diminuer la hauteur de la 
                tête de puits offshore et de l'arbre de Noël. Soupape de sécurité hydraulique dans le 
                diamètre de la dérive principale pour obtenir l'arrêt à distance de la tête de puits en cas d'urgence.
                Bobine de tête de tube séparée. Les parties supérieure et inférieure sont reliées par une pièce de verrouillage de 
                type insert. Cette structure signifie que l'arbre de Noël peut être ajusté dans n'importe quelle direction pendant 
                l'installation. Équipé d'un passe-câble BIW et d'une bride d'adaptation et d'un support installés`
            },
            p14: {
                title: "Tête de puits de fracture et arbre de Noël",
                desc: `Raccordement à bride standard API 6A Haute pression jusqu'à 2000 psi. Classe de matériaux supérieure HH
                Longue durée de vie. Performances d'étanchéité stables. Pression de service 10000-20000 si
                Température de fonctionnement nominale. PU Taux de performance :PR1PR2 Diam. dérive(po):2''9/16;3''1/8;4''1/8 & 7
                `
            },
            p15: {
                title: "Tête de puits d'injection d'eau et arbre de Noël",
                desc: `Injection d'eau à haute pression. Peut être utilisé pour le rinçage des puits
                De petite taille et léger. Technique de pulvérisation de métaux en alliage à base de nickel. performances d’étanchéité et anticorrosion supérieures
                Installer le volant dans deux directions sur la vanne de sectionnement. Combinaison de deux vannes, croix et bride 
                d'adaptation. diminue considérablement la hauteur de la tête de puits. Épaisseur optimale des brides supérieure et 
                inférieure de la tête du tube. diminue efficacement le poids et la taille. Pression nominale (psi): 7250
                Diam nominal (po):2''9/16 Diam de dérive de la tête de tube (po) : 6,3 Connexion: bride
                `
            },
            p16: {
                title: "Tête de puits de récupération de gaz et arbre de Noël",
                desc: `Tige composite à structure spéciale. Excellentes performances d'étanchéité et faible couple de rotation.
                Obturateur et siège de vanne de pulvérisation en métal. La surface d'étanchéité est 
                très dure et s'ajuste avec précision. Performances supérieures d’étanchéité aux gaz et 
                performances résistantes à la pression et à la corrosion. Pression de travail de 2000 à 20 000 psi
                -460C à 1200C température de fonctionnement. Diam. dérive (po):2’’9/16,3’’1/8
                `
            },
            p17: {
                title: "Tête de puits de pompe submersible électrique, arbre de noël",
                desc: `Raccordement à bride standard API 6A. Deux types : pénétrateur et pénétration de câble.
                Installation et retrait faciles Performances d'étanchéité fiables. Longue durée de vie
                Pression de service 2000-20000si -460C à 1210C température de fonctionnement. Diam. dérive (po):2’’9/16,3’’1/8
                `
            },
            p18: {
                title: "Tête de puits et valve d'arbre de Noël",
                desc: `Flux bidirectionnel. Tige non montante/montante. Faible besoin de couple pour l'ouverture et la fermeture
                Performances API 6A PR1PR2. Volant à connexion/déconnexion rapide. Obturateur et siège de vanne flottants
                Pression de travail de 2000 à 20000 psi. Température de fonctionnement de -290C à 1210C
                `
            },
            p19: "PUMPING UNIT- BENEFIT",
            p20: {
                title: "Unité de pompage API",
                desc: `Appliquer strictement la norme API 11E. Réducteur avec qualification de production API.
                Outillage de production complet 5 ans de garantie. L'utilisation de la forge dans les pièces clés
                `
            },
            p21: {
                title: "Unité de pompe standard",
                desc: `Réduire efficacement la consommation d’énergie. Convient à la production de pétrole lourd. Fréquence de course longue et faible. Moins d'ajustement de la longueur de course et du rapport de course
                `
            },
            p22: "SUCKER ROD PUMP- BENEFIT",
            p23: {
                title: "Pompe à tige",
                desc: `La pompe à tige peut être retirée directement du puits. L'inspection et le reconditionnement des puits sont pratiques. Réduisez le coût du test de pompage. Convient aux puits plus profonds. Longueur du canon (pi) : 2-34. Longueur du piston (pieds) : 2-24. Diamètre de la pompe (po):1’’1/4-2’’1/2
                `
            },
            p24: {
                title: "Plongeurs de pompe à tige de pompage Anti-usure",
                desc: `Anticorrosion. Le piston peut être fabriqué avec une broche en ni-cu à extrémité en monel. Diamètre nominal(po)(po):1’’1/16-4’’3/4. Longueur maximale (pi) : 34`
            },
            p25: {
                title: "Pompe à tube",
                desc: `La structure est simple mais solide et durable. Le prix est bas. A une production élevée de fluide dans un tube de même diamètre. Convient aux puits plus profonds. Longueur du canon (pi) : 2-34. Longueur du piston (pieds) : 2-24. Diamètre de la pompe (po):1’’1/2-5’’`
            },
            p26: {
                title: "Pompe de contrôle du sable à piston long",
                desc: `Adopter une structure à piston long et à barillet court Utiliser le dispositif de grattage et de scellement du sable Empêcher le sable d'enterrer le piston Convient au puits de pétrole dont la teneur en sable est inférieure à 3 % Connecter les tubes (po):2''7/8NU & 3''1/2NU Connectez la tige de pompage (in): 3/4 Diamètre nominal(po):1’’1/2;1’’3/4 & 2’’1/4 Modèle :20-150THFS/20-175THFS/30-225THFS
                `
            },
            p27: {
                title: "FOPS à piston long et pompe anticorrosion",
                desc: `Structure unique pour empêcher le sable de 
                rester coincé. Empêcher l'enfouissement du sable Possède une forte propriété anticorrosion Convient aux puits de sable et aux puits de pétrole troublés par la cire Réduisez les temps de test des pompes et augmentez l’efficacité économique Connecter les tubes (po):2''7/8NU & 3''1/2NU Connectez la tige de pompage (in): 3/4 Diamètre nominal(po):1’’3/4 & 2’’1/2 Modèle :25-175THFL/25-175THFLF/30-225THFL/30-225THFLF
                `
            },
            p28: {
                title: "Contrôle du sable et centralisation Pompe à tige",
                desc: `Évitez le balancement du corps de la pompe et réduisez l’abrasion Empêcher l'enfouissement du sable Le travail est stable et a une grande efficacité Convient au puits de pétrole à haute teneur en sable Connecter le tube (po):2''7/8NU Connectez la tige de pompage (in): 3/4 Diamètre nominal(po):1’’1/2;1’’3/4 & 2’’1/4 Modèle :20-125RHBC/25-150RHBC/25-175RHBC
                 `
            },
            p29: {
                title: "Pompe à valve mécanique à double piston",
                desc: `Structure unique à double piston Évitez le blocage du gaz Améliorer l'effet de la pompe et prolonger la durée de service Puits de pétrole lourd et puits de gaz appropriés, même puits déviés Connecter le tube (po):2''7/8 & 3"1/2 Connectez la tige de pompage (po): 3/4 et 7/8 Diamètre nominal(po):1’’3/4, 2’’1/4,2”3/4 & 3”1/4 Modèle :25-175THSJ/25-225THSJ/30-275THSJ/30-325THSJ                
                `
            },
            p30: {
                title: "Retour hydraulique et pompe anticorrosion",
                desc: `En effet, combiné par deux pompes de diamètres différents Utilise la théorie de la rétroaction hydraulique, réduit la rupture de la tige de pompage Longue durée de vie Très bonne propriété anticorrosion Connecter le tube (po):2''7/8 & 3"1/2 Connectez la tige de pompage (po): 3/4 et 7/8 Diamètre nominal(po):1''3/4, 1"1/2 2''1/4,1"1/2 ,2"3/4 & 1"3/4                
                `
            },
            p31: "PRESSURE VESSEL- BENEFIT",
            p32: {
                title: "2 Tank",
                desc: `Vaporisation efficace Économie de carburant Empêcher le matériel de paralyser Capacité de manutention maxi/par heure :5T;6T;8T;10T;15T;20T Importation/exportation mm 110/110 ; 110/110;160/160;160/160;200/200;200/200`
            },
            p33: {
                title: "3 phase separator",
                desc: `Performances anticorrosion. Fonctionnement antistatique élevé Coût faible Environnement de protection Type vertical et horizontal
                 `
            },
            p34: {
                title: "Séparateur de gaz naturel",
                desc: `Efficacité épuratoire élevée. Grande capacité de poussière. Fonctionnement stable. Faible coût d’investissement et d’exploitation. Facile à installer et à utiliser Type vertical et horizontal`
            },
            p35: {
                title: "Réservoir de désulfuration",
                desc: `Efficacité de désulfuration élevée. Facile à installer et à utiliser. Diamètre du réservoir hauteur mm : 300mmx600mm. 1200mmx3720mm. 1800mmx3000mm. Volume(m3):50-4000`
            },
            p36: {
                title: "Reservoir de stockage de pétrole brut",
                desc: `Performances stables. Haute sécurité. Protection contre la corrosion
                `
            },
            p37: "OUTIL DE RÉCUPÉRATION D'HUILE",
            p38: {
                title: "Pince à tige polie",
                desc: `La pince est fabriquée conformément 
                aux exigences des normes 
                industrielles-sy/T5029-2013 et à la 
                spécification API 11B.
                Aucune bosse, réduit efficacement les 
                dommages à la tige polie et à 
                l'emballage du presse-étoupe 
                Disponible sur trois modèles
                Peut être utilisé avec deux pinces du 
                même modèle et de la même charge 
                pour augmenter la charge de travail                
                `
            },
            p39: {
                title: "Rotateur de tige",
                desc: `Peut être utilisé dans le système de 
                production d'huile de pompe à tige de 
                pompage
                Principe de fonctionnement : assure la 
                rotation de la tige de pompage à un 
                service stable
                Effet : réduire l'usure excentrique et 
                prolonger la durée de vie de la tige de 
                pompage et du tube
                Production annuelle de 5000 pièces
                Rotation de la charge (lbf) : 36 000/13 
                500/13 500               
                `
            },
            p40: {
                title: "Presse-étoupe pour champs pétrolifères ",
                desc: `Il dispose d'un brevet de modèle 
                d'utilité N°ZL2006 2 0085213X
                Réglage de la déviation et anti usure excentrique : auto alignement axial, décalage radial
                Entretien simple et durée de vie 
                plus longue
                Le presse-étoupe de type combiné 
                a un effet d'étanchéité plus fiable
                Fonction auxiliaire : prolonger la 
                durée de vie de la boîte à garniture 
                et optimiser dans une certaine 
                mesure l'état de fonctionnement de 
                la tige polie
                Anti-fuite et antivol
                Diamètre maximum (po): 7
                Hauteur maximale (mm): 18
                Pression de travail: 3600psi
                Mode commun : type de pince et de 
                couplage
                Alignement maximum et réglage 
                dévié : Axial +/-10° ;radial+/-20 mm
                Suite tige polie 
                diam(po):1”;1”1/8;1”1/4;1”1/2
                             
                `
            },
            p41: {
                title: "Boîte à garniture de tige polie à réglage fin à long terme ",
                desc: `Les pièces d'étanchéité sont 
                constituées de grades I, II et III
                Entretien pratique, structure 
                compacte et facile à utiliser
                Longue durée de vie du presse étoupe
                Pression de travail: 3600psi
                Connexion inférieure : type de 
                pince et de filetage
                Dimension:190*190*400mm
                Suite tige polie;             
                `
            },
            p42: "Chaudière à vapeur pour gisement de pétrole",
            p43: {
                title1: "Benefit",
                desc1: `Sécheresse élevée (au-dessus de 85 %). Efficacité thermique élevée (supérieure à 90%). Anti-calcaire. Surveillance à distance et sans surveillance. Économie d'énergie et protection de l'environnement. Traitement et réinjection des eaux usées           
                `,
                title2: "Data sheet",
                desc2: `Capacité d'évaporation nominale (t/h) : Sous-critique : 5-50 et super critique : 6,5-40. Pression de service (MPa) : 15/17,2/21 et ≤ 26. Température de la vapeur (°c) : 342-370 et 390. Sécheresse de la vapeur (%) : 85 et 85. Efficacité thermique (%) :≥90 et ≥90 Méthode de combustion : gazole de pétrole brut et gazole de pétrole brut. Mode de transport : monté sur patin monté sur remorque et monté sur patin monté sur remorque
                `
            },
            p44: "TÊTE DE PUITS DE RÉCUPÉRATION DE GAZ ET ARBRE DE NOËL",
            p45: {
                title1: "Benefit",
                desc1: `Résistance aux températures élevées. Résistance aux hautes pressions Performances fiables. Longue durée de vie. Conçu pour s'adapter au design
                `,
                title2: "Data sheet",
                desc2: `Pression nominale (psi): 3000-20000 Diam nom(po):2''1/16;2''9/16;3''1/8;5"
                Niveau de spécifications de production : PSL1 PSL2 PSL3 PSL3GPSL4
                Taux de performance :PR1PR2 Classe de matériau : AA 
                BBCCDDEEFFHH/ Profondeur maximale du puits (pi) : 16 400
                Température de travail maximale (°C) : 390°
                `
            },
            p46: "TUBES ET BOÎTIER ISOLÉS SOUS VIDE",
            p47: {
                title1: "Benefit",
                desc1: `Catégories isolées D et E. Longue durée de vie. Les connexions du tube intérieur et du tube extérieur sont toutes deux disponibles. Propriétés d'isolation supérieures`,
                title2: "Data sheet",
                desc2: `OD(in) xID(in):
                3”1/2x2”3/8;4”1/2x2”7/8;4”1/2x3”1/2;5”x3”1/2;5”1/2x4”1/2
                7”1/2x5”1/2.
                Steel grade:N80;L80 & P110
                Connection: inner, outer & extra line connections
                Thread:3”1/2USS;4”1/2BC; 4”1/2BC;5BC; 5”1/2BC;7”B
                `
            },
            p48: "TUBES ET BOÎTIER ISOLÉS SOUS VIDE",
            p49: {
                title1: "Benefit",
                desc1: `Production de vapeur et d'huile d'injection à double usage
                De bonnes performances donc une meilleure façon d’éviter la 
                mise à l’échelle.
                Efficacité de production élevée
                Largement utilisé dans le processus d'injection de vapeur`,
                title2: "Data sheet",
                desc2: `Model:40-275THZCYT Nominal diam(in):2”3/4
                Stroke length(ft):20 Connecting tubing(in):4”1/2
                Connecting sucker rod (in):7/8
                Pump constant(K):5.518 Largest diam(mm):132.1
                `
            },
            p50: "TUBES ET BOÎTIER ISOLÉS SOUS VIDE",
            p51: {
                title1: "Benefit",
                desc1: `Mesure précise du pétrole, de l'eau et du gaz
                Interface conviviale
                Opération facile
                Conception modulaire intégrant les dernières 
                technologies en matière de systèmes de 
                mesure
                Transmission de données à distance, 
                permettant l'acquisition de données en temps 
                réel et la numérisation du champ pétrolier`,
                title2: "Data sheet",
                desc2: `Universal gas & liquid automatic metering device
                Metering range: gas range(nm3/d):0-1000
                 & Liquid range(m3/d):0-50
                Metering error: Air current(m3/d):+/-5.0
                 & liquid current (m3/d):+/-3.0
                Nominal Pressure(psi):350
                Working temp(°C):5-80
                Power supply AC480V60H
                `
            },
            p52: "AUTOMATIC OILFIELD WORKOVER RIGS",
            p53: {
                title1: "Benefit",
                desc1: `Fonctionnement automatisé à un 
                seul bouton avec un haut degré 
                d'automatisation
                Réduit le coût de la main d’œuvre
                Diminuer l’intensité du travail
                Le fonctionnement est sûr et fiable`,
                title2: "Data sheet",
                desc2: `Minor work over automatic system 
                Ambient temp(°C):-20-45
                Ambient humidity(°C):≤90%+20°C
                Tripping speeds(s/joint):120
                Rated pressure of hydraulic system(psi):2300
                Electricity demand 380V(+/-10%):80Kw
                Air pressure(psi):≥116
                Loading efficiency(joints/hour):30
                Loading capacity(lb):Rated capacity 90 000 max capacity 
                15100
                Loading power (kw):37
                Applied tubing(in):2”3/8;2”7/8;3”1/2 NU tubing
                Operating staff(persons):3
                `
            },

            p54: "DRILLING & COMPLETION TECHNOLOGY AND PRODUCTS",
            p55: {
                title: "Liner Hanger",
                title1: "Benefit",
                desc1: `Performances d'étanchéité renforcées entre le carter 
                supérieur et le tuyau d'échappement avec d'excellents 
                résultats qui empêchent efficacement la corrosion du 
                gaz C02.
                Économie potentielle de coût du manchon pour les 
                puits de louange d'exploration et d'évaluation.
                Facile et rapide à sceller les trous ouverts, les vieux 
                puits ou à approfondir la cimentation
                Un moyen flexible d’achèvement et une opération de 
                cimentation plus sûre
                Scelle les sections endommagées du carter supérieur 
                (corrosion, fracture)`,
                title2: "Data sheet",
                desc2: `Modèle :SSX
                Capacité nominale (lb):352 000
                Pression d'assise (psi): 1000-1700
                Capacité de charge du manchon de support (lb): 35 
                200
                Capacité d'étanchéité (psi): 3600
                `,
            },
            p56: {
                title: "Variable centralizer oil & gas",
                title1: "Benefit",
                desc1: `Améliore la structure de support 
                centrée sur la colonne et une meilleure 
                isolation zonale
                Maintient les zones d'écoulement de 
                l'espace annulaire et la densité de 
                circulation équivalente à une valeur 
                appropriée ni trop élevée ni trop basse.
                Ne pas poignarder ou rayer les parois 
                du puits.
                Forte capacité portante, soutenant 
                efficacement le mur du puits`,
                title2: "Data sheet",
                desc2: `Modèle :FZQ140-185 et FZQ178-205
                Diamètre maximum (mm):185 & 205
                Mini alésage (mm): 121 et 157
                Diamètre maximum d'expansion (mm): 
                335 et 380
                Pression ouverte (psi): 2000
                `,
            },
            p57: {
                title: "Packer oil & gas",
                title1: "Benefit",
                desc1: `Récupération et production pétrolière améliorées
                Améliorer l'efficacité des puits grâce à la mise en scène 
                et à la formation de la production
                Aide à terminer le bloc de segments à plusieurs niveaux 
                lorsque l'eau sort plus tard.
                Empêcher le pétrole, le gaz et l’eau de s’écouler entre 
                les couches
                Améliorer l’efficacité de la fracturation du pétrole et du 
                gaz non conventionnels`,
                title2: "Data sheet",
                desc2: `Modèle : Y341-115A; Y221-115 et Y344-150
                Type : compression
                Type de joint : hydraulique ; faire pivoter la pompe et 
                l'hydraulique
                Pression de service :2000;3000 & 10 000
                Température maximale : 120 ; 120 et 150
                Filetage de connexion : 2"7/8EU
                Utilisation : injection d'eau, récupération d'huile et 
                injection d'eau ou récupération d'huile
                `,
            },
            p58: "TUBE DE REVÊTEMENT HDPE/EXPE",
            p59: {
                title1: "Benefit",
                desc1: `Moins d'usure de la tige
                Résistance supérieure aux produits chimiques et à la 
                corrosion.
                Temps libre de réparation prolongé 4,7 fois
                Résistant à abrasion.
                Température de travail jusqu'à 100°C.
                Détective défectueux en raison d'une inspection de 
                qualité à 100 % avant la livraison.`,
                title2: "Data sheet",
                desc2: `Taille:2''7/8NU/2''7/8EU//3''1/2NU/3''1/2EU//4''1/2NU/4''1/2EU
                ID (mm): 62 et 54/76 et 67/100,54 et 89
                Accouplement (OD: mm): 88,9 et 93,17/107,95 et 
                114,30/132,08 et 141,30
                Accouplement de tube de revêtement (mm): 
                151&158/166&171/182&186
                Longueur évasée (mm) : 7-9&8-10/8-10&8-10/10-12&10-12
                Poids maximum (°C) : 100
                `,
            },
            p60: "REVÊTEMENT EN RÉSINE ÉPOXY TUBE ANTI CORROSION",
            p61: {
                title1: "Benefit",
                desc1: `Haute résistance mécanique, excellente ténacité
                Le revêtement n'est pas toxique et non polluant
                Excellentes performances physiques et mécaniques
                Bonne résistance à la chaleur sous la température de 150°C
                Surface lisse pour empêcher la saleté de s'accumuler`,
                title2: "Data sheet",
                desc2: `Résistance aux chocs :≥11J et SY/T0442
                Résistance à l'abrasion 
                (1000g;1000rotation):≤20mg & GB/T1768
                Force d'adhérence : grade 1-3 et SY/T0442
                Nombre de broches du trou≤1/m2 et SY/T0063
                Épaisseur du revêtement : 250-300πm et 
                SY/T0066
                `,
            },
            p62: "TIGE DE VENTOUSE ENDUIT",
            p63: {
                title1: "Benefit",
                desc1: `Base sur la technologie de Weatherford
                Canne neuve et occasion toutes disponibles, 
                Pourriez-vous réduire la charge de l'unité de 
                pompage Économie d'énergie
                Bonnes performances anti-tartre et anti corrosion`,
                title2: "Data sheet",
                desc2: `Matériau enduit : nylon composite
                Épaisseur enduite (mm) : 2 (selon l'exigence du 
                client)
                Longueur de la loi du pulvérisateur (mm): 320
                Épaisseur de la loi du pulvérisateur (mm): 0,2
                `,
            },
            p64: "TUBES EN ALLIAGE DE TUNGSTÈNE PLAQUÉ",
            p65: {
                title1: "Benefit",
                desc1: `Grande capacité de combinaison
                Résistance aux hautes températures
                Pas de flocon ni de pollution
                Technologie avancée à base de nickel`,
                title2: "Data sheet",
                desc2: `Dureté du placage : 50-550HV (état plaqué)
                940-1100HV (traitement thermique)
                Résistance à l'usure : 2 à 3 fois supérieure à celle du chromage
                Résistance à la corrosion : résistance aux services agressifs 
                comme H2S, C02, NACL,HCL.
                Efficacité actuelle : 80 % à 85 %
                Taux de dépôt : 35µm-45µm/h
                Adhérence : 8 kg/mm2, placage sans chute à haute température 
                et pression
                `,
            },
            p66: "TÊTE DE PUITS ANTI H2S ET ARBRE DE NOËL",
            p67: {
                title1: "Benefit",
                desc1: `Soupape de sécurité dans le diamètre de la 
                dérive principale pour obtenir l'arrêt de la tête 
                de puits en cas d'urgence
                Toutes les pièces sous pression sont 
                fabriquées en alliage de qualité supérieure 
                forgé grâce à un traitement thermique 
                approprié et un NDT, elles ont une bonne 
                capacité de résistance aux sulfures.
                Support de tube à joint métal sur métal avec 
                une longue durée de vie
                Vanne à tige montante avec tige de queue 
                équilibrée pour éviter que la vanne ne reste 
                coincée sous haute pression
                Les sorties latérales avec filetage VR 
                permettent le remplacement du robinet-vanne 
                sous pression
                `,
                title2: "Data sheet",
                desc2: `Pression de service (psi): 10 M, 15 M et 20 M
                Température de fonctionnement (°C):-46°C-120°C
                Matériel :DD,EE,FF
                Niveau de spécification de production : PSL2, PSL3, PSL3G, 
                PSL4
                Taux de performance :PR1PR2
                Diamètre de dérive (po):2"9/16;3"1/8
                `,
            },
            p68: "POMPE À TIGE DE POMPE ANTICORROSIVE",
            p69: {
                title1: "Benefit",
                desc1: `Anti-usure
                Anticorrosion
                Chromé et nickelé-phosphore
                Pour le placage au nickel et au phosphore, nous 
                pouvons faire des identifications, des diamètres 
                extérieurs et des filetages pour les fûts en laiton.                
                `,
                title2: "Data sheet",
                desc2: `Type: pompe à tige à paroi épaisse
                Pompe à tige à paroi mince : 1''1/4-2"1/2
                Pompe à tube d'alésage : 1"3/4-3"3/4
                Longueur du piston (pieds) : 2-24
                Longueur du canon (pi) : 2-34
                Matériau du baril :
                A4 : plaque chromée sur alliage NI/CU
                A5 : plaque chromée sur laiton
                B4 : plaque chromée lourde sur alliage NI/CU
                A5 : plaque chromée lourde sur laiton
                Plongeur de matériau :
                D1-D3 : pulvérisation de métal avec extrémités en 
                alliage NI/CU
                E1-E3 : métal pulvérisé avec extrémités de broches 
                nickelées et toute la longueur du diamètre intérieur
                F1-F3 : métal pulvérisé avec extrémités de broches 
                nickelées et toute la longueur du diamètre intérieur
                Milieu de corrosion : H2s ; C02 ; eau saline, 
                oxygène
                `,
            },
        }
    }

}