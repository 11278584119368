import React, { Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { French } from "./langue/French";
import { English } from "./langue/English";

function App() {

  const Home = React.lazy(() => import('./pages/home'))
  const About = React.lazy(() => import('./pages/about'))
   const Service = React.lazy(() => import('./pages/service'))
   const Qualite = React.lazy(() => import('./pages/Qualite'))
  const Blog = React.lazy(() => import('./pages/blog'))
  const Contact = React.lazy(() => import('./pages/contact'))
  const NotFound = React.lazy(() => import('./pages/nofound'))
  const Product = React.lazy(() => import('./pages/Product'))

  function Content() {

    return (
      <Suspense>
        <Routes> 
          <Route exact path={"/"} element={<Home French = {French} English = {English} />} />
          <Route exact path={"/about"} element={<About French = {French} English = {English} />} />
          <Route exact path={"/contact"} element={<Contact French = {French} English = {English} />} />
          <Route exact path={"/service"} element={<Service French = {French} English = {English} />} />
          <Route exact path={"/qualite"} element={<Qualite French = {French} English = {English} />} />
          <Route exact path={"/product/:id"} element={<Product French = {French} English = {English} />} />

          <Route exact path={"/*"} element={<NotFound French = {French} English = {English} />} />
        </Routes>
      </Suspense>
    );
  }

  return (
    <Router>
      <Content />
    </Router>
  )

}

export default App;
