export const English = {
    navbar: {
        follow: "Follow us",
        home: "Home",
        product: "Products",
        about: "About",
        blog: "Blog",
        service: "Services",
        contact: "Contact us",
        lang: "Language",
        qualite: "Qualities",
        list_pro: {
            p1: "Steel pipe",
            p2: "CONVENTIONAL DRILLING AND RECOVERY EQUIPMENT",
            p3: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p4: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT"
        }
    },
    footer: {
        copyright: "All rights reserved",
        privacy: "Privacy policy",
        location: "Ngouaboussi Quater, CQ519, Pointe-noire, Congo",
        Tubing: "OCTG Tubing",
        Casing: "OCTG case",
        Vaccum: "Vacuum induction tubes and casings",
        Anti: "Anti-corrosion and anti-wear tubes",
        Precision: "Precision rolling pipe",
        list_pro: {
            p1: "Steel pipe",
            p2: "CONVENTIONAL DRILLING AND RECOVERY EQUIPMENT",
            p3: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p4: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT"
        }
    },
    notfound: {
        no: "Page not found",
        message: "We're sorry, the page you are looking for does not exist on our site or is under construction!",
        home: "Return to home page"
    },
    contact: {
        conctact: "Contact us",
        home: "Home",
        noclick: "Contact",
        message: "If you have any questions, please do not hesitate to contact us. We would be happy to help",
        send_message: "Leave us a message",
        speciality: {
            message: "We specialize in",
            exp: "30+ years of manufacturing experience",
            garanty: "Up to 20 years output guarantee",
            charge: "Complete support for your personalized needs",
            advantage: "More price advantage and quality control ability",
            otg: "OCTG tube and housing manufacturer"
        },
        advantage: {
            message: "Why are we different ?",
            OCTG: "OCTG Solution Provider",
            assurance: "Class quality assurance",
            price: "Competitive price",
            flexible: "Tailor-made flexibility",
            litle: "Small simple workshop",
            quality: "Very poor quality",
            badprice: "Very expensive price",
            sell: "Force the sale to you"
        },
        welcome: "You are welcome, you can visit us at any time",
        location: "Ngouaboussi Quater, CQ519, Pointe-noire, Congo",
        name: "Your name",
        email: "Your email address",
        phone: "Phone international format",
        object: "The subject of the message",
        req: "Enter your message",
        btn: "Send"
    },

    about: {
        about: "Who are we ?",
        home: "Home",
        noclick: "About",
        details: {
            p1: `AFRIWELLGROUP, a Congolese company, specializes in promoting, bidding and negotiating in the sale of 
            conventional and hydraulic well workover equipment, capable of working on/offshore, drilling and completion 
            equipment that meets API & product requirements chemicals.`,
            p2: `Afriwellgroup is an Exclusive Agency of Professional manufaturer of OCTG tubing & casing, Drill bit 
            AFRIWELLGROUP, SARL represents in this line three companies manufacturing work over Equipment & 
            Tools, drilling and completion equipment, fittings whose operational head office is in China & the USA 
            (Houston), distribution of chemical products and inspection of drilling rigs, platforms and refinery 
            We can fully support you on your drilling & work over business.`,
            p4: `Direction`,

            p5: `The Management of AFRIWELLGROUP is formed by an experienced team, composed for the moment by its 
            current CEO, Mr MABAYA BAVOUEZA Christian Audrey, Consultant in Business & Development Drilling 
            engineering & Maintenance, Polytechnician ENSP Congo, IFP certified in Design, Planning & Practical 
            construction of the Oil Well, MBA ESLSCA from Paris (France), Master in control Management of SUP 
            VENTES Ile de France, our experienced team is well trained, motivated and constantly seeks to question 
            itself to find better ways of carry out their activities and optimize our processes to better support our clients in 
            oil and gas companies. `,
        },
        advantages: {
            message: "Why are we different ?",
            OCTG: "OCTG Solution Provider",
            assurance: "Class quality assurance",
            price: "Competitive price",
            flexible: "Tailor-made flexibility",
            litle: "Small simple workshop",
            quality: "Very poor quality",
            badprice: "Very expensive price",
            sell: "Force the sale to you"
        },
        an: "Years of experience",
        year: "years",
        exp: "Team members",
        customers: "Satisfied customers",
        projects: "Projects completed",
        partners: "Official partners",
        advantage: "Ideal solution with four years of experience",
        next: "Read more",
        prev: "Reduire",
        seo: "CEO & Founder",
        chooseus: "WHY CHOOSE US",
        people: "Why do people trust us?",
        contact: "Let Afriwell group support your business, solve your problems and reduce your costs",
        btn: "contact us now"
    },

    home: {
        contact: "Let Afriwell group support your business, solve your problems and reduce your costs",
        btn: "contact us now",
        carousel: {
            weare: "Welcome to Afriwell group",
            details: "exclusive agency of professional manufacturer of OCTG tubes and casings, drill bits"
        },
        /* fab: "Trusted manufacturer for you",
         ad: "Afriwell Group stands out for its expertise in the design and manufacturing of high quality OCTG tubes and casings, as well as its diverse range of products including vacuum insulation tubes and casings, rolling pipes precision, and anti-corrosion and anti-wear tube solutions.",
         , */
        fab: "About",
        ad1: `AFRIWELLGROUP, a Congolese company, specializes in promoting, bidding and negotiating in the sale of 
         conventional and hydraulic well workover equipment, capable of working on/offshore, drilling and completion 
         equipment that meets API & product requirements chemicals.`,
        ad2: `Afriwellgroup is an Exclusive Agency of Professional manufaturer of OCTG tubing & casing, Drill bit 
         AFRIWELLGROUP, SARL represents in this line three companies manufacturing work over Equipment & 
         Tools, drilling and completion equipment, fittings whose operational head office is in China & the USA 
         (Houston), distribution of chemical products and inspection of drilling rigs, platforms and refinery 
         We can fully support you on your drilling & work over business`,
        about: "Who are we ?",
        btier: "OCTG tubes and casings",
        product: "Flagship products",
        list: {
            btn: "See more Details",
            p1: {
                title: "OCTG tubes",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p2: {
                title: "OCTG case",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p3: {
                title: "Vacuum induction tubing",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p4: {
                title: "Precision rolling pipe",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p5: {
                title: "Tungsten plated tube",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p6: {
                title: "Polycore Tubes",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p7: {
                title: "HTPT coating tube",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            p8: {
                title: "EXPE Coating Surface Pipeline",
                p1: "100,000 tonnes per year",
                p2: "20 years"
            },
            devis: "Request a quote",
            plus: "See more products"
        },
        why: "What are the reasons why Afriwell Group exports its products?",
        years: "more than 60 countries",
        reason: {
            p1: "Our partners have 50 year’s drilling and recovery equipment manufacturing experience",
            p2: `Our partners have one stop solution for all drilling and recovering products`,
            p3: `Our partners have complete certification and integrated solutions`,
            p4: `Our partners have special OCTG Tubing & Casing can effectively solve the difficult problems in oil field production such as corrosion and wear of oil tubing, scaling of water injection well, heat preservation in steam injection.`,
            p5: `Our partners have 30+ Years of OCTG Tubing & Casing Manufacturing Experience.`,
            p6: `Our partners have  20 years of International standard OCTG Tubing & Casing experience`,
            p7: `Our partners have 20＋ years of experience in EUE OCTG Tubing & Casing.`,
            p8: `Our partners have OCTG Tubing & Casing has the best cost performance ratio in China & USA.`,
        },
    },
    qualite: {
        about: "Our Qualities",
        home: "Home",
        noclick: "Qualities",
        qualite: {
            title: "HOW DO OUR PARTNERS CONTROL PRODUCTION QUALITY ?",
            p1: `Physical and chemical, measurement and precision, thermal-instrumentation laboratories, we carry out 
            physical and chemical performance experiments on each batch of raw materials entering the factory.`,
            p2: `Complete quality management system compliant with the requirements of ISO9001, APIQ1 & HSE certified 
            by DNV, we maintain very strict quality control procedures by our lab. measurement & precision, Thermal instrumentation and follow-up certifications by inspection companies`,
            p3: `Certified workshops with API5CT, API11AX, API6A, API16A, API11E and ASMES, U monograms. 
            Any procedure during production or before delivery from factory, we accept inspection from any third party 
            institute as customer's requirement.`,
            p4: ``,
            p5: ``
        },
        confiance: {
            title: `WHY SHOULD WE TRUST US MORE APART FROM RESPECTING INTERNATIONAL STANDARDS ?`,
            p1: {
                title: "Our Experience",
                p1: `+30 years of experience in tubing & casing machining (API5CT).`,
                p2: `+10 years of experience in the online production and development of Precision-rolling.`,
                p3: `+30 years of experience in the design & machining of pumping units (API11E).`,
                p4: `+40 years of experience in the design & machining of downhole pumps (API11AX) with (CE).`,
                p5: `+40 experience in the design & machining of well systems & Christmas trees (PR2)/API6A/API16A.`,
                p6: `+30 years of experience in the design & manufacturing of Tanks, separators (ASMEUS).`,
                p7: `+25 years of experience in the design and manufacturing of Tertiaryoil recovery system`
            },
            p2: {
                title: "CUSTOMER ASSISTANCE FOR THE CHOICE OF TUBING, CASING & FUND PUMPS?",
                p1: `For oil wells containing H2S, we normally select the grade material UNDER SS, for example L80 and 
                L80SS.`,
                p2: `For CO2-containing oil wells, we normally select chrome-added material, for example L80-13Cr, or higher 
                grade.`,
                p3: `NOTE: with anti-corrosion and anti-abrasive performance.`
            },
            p3: {
                title: "COMPLIANCE WITH THE FAILURE POLICY OF VIT & VIC TUBING & CASINGS ",
                p1: `The failure and warranty of vacuum tubes and casings are a very important clue for oilfields and customers, 
                When they request VIT and VIC, they often want to confirm the failure and warranty policy, It requires 
                according to the SY/T5324-2013 standard: for the former VIT after steam injection, when its thermal 
                conductivity is higher than 0.1W/(m·℃), it will be considered a failure.`,
                p2: `Besides the quality problem, this problem is mainly due to the working environment, such as high sulfur 
                wells where the tubing body and welds are much more corroded than in normal wells.`,
                p3: `Therefore, we recommend customers to provide detailed information about the condition of the wells. 
                According to customer data, we can design special VITs.`
            },
            p4: {
                title: "COMPLIANCE WITH THE WARRANTY POLICY & STOCKS OF TUBING & CASING VIT & VIC",
                p1: `Generally, under regular service and stock conditions, we can provide a warranty of up to 12 months for 
                wells and 24 months for stock. When used in a steam stimulation well, the service life must be at least 30 
                stimulation cycles or at least 24 months. `,
                p2: `Under the warranty, defective products will be modified free of charge for the customer after receiving a 
                formal non-conformity report from a third party in the event of a failure caused by a product quality problem.`,
                p3: `Outside of warranty, we are also willing to help the customer to resolve the product failure problem if the 
                customer's requirements, but the cost will be borne by the customer.`,
                p4: `But above all, the state of each well is different, such as the corrosion environment, the injection medium, 
                the well temperature and the length of the tube string, which will affect the failure and guarantee of vacuum 
                tubes and casing.`
            },
            p5: {
                title: "REDUCE THE RISK OF DAMAGE IN THE TRANSPORT OF TUBINGS & CASINGS.",
                p1: `We can pack the tubes according to customer's requirements otherwise in bundles and each bundle weighs 
                at least 2 tons.`,
                p2: `We assume your loading, unloading and transport risks by charging prices in CIF & DAP (from 5MU$D). 
                We can provide customers with the following documents:`,
                p3: `MTC(MillTest Certificate) and other inspection report documents, including hydrostatic report, 
                physical and chemical examination report, thread inspection report, etc., in any language version 
                according to customer's requirements.`,
                p4: `TALLYSHEET: showing all information about the tubes or casings, including size, unit weight, unit 
                length, total length, packages, quantity/package.`,
                p5: `We provide all necessary documents for destination customs clearance and other documents to help 
                customer obtain the country's preferential policy.`,
                p6: ``,
            },
            p6: {
                title: "DELIVERY TIME ADAPTED TO THE CUSTOMER’S PACE",
                p1: `Reduction of manufacturing time (by a system of ¼).`,
                p2: `Competitive prices`,
                p3: `Presence of backup workshops in case of emergency`,
                p4: `Use of mini stock available in China & Singapore`,
                p5: `Assignment of a customer’s own supply boat and for special orders from 30MU$D 
                After-sales service (in the workshop then at the customer’s premises)`,
                p6: ``
            }
        }
    },

    services: {
        about: "Our Services",
        home: "Home",
        noclick: "services",
        services: {
            p1: {
                title: `TECHNICAL ASSISTANCE, DRILLING & RECONDITIONING BUSINESS DEVELOPMENT`,
                p1: `Drilling Engineering & Maintenance`,
                p2: `Project management`,
                p3: `Audit & Survey of drilling rigs & platforms`,
                p4: `Analysis & strategy and development of drilling and workover business`,
                p5: `Development of the contact network to attract new customers`,
                p6: `In search of new challenges, monitoring & development of projects`,
                p7: `Make sales projections and forecast revenues, based on the budgeted result`
            },
            p2: {
                title: "MANAGEMENT AND OPERATIONS OF TERRESTRIAL DEVICES AND PLATFORMS",
                p1: `Our expertise in the management, maintenance and operation of on/offshore platforms, ranging from devices
                modular workover equipment for landmine operations and drilling rigs, is this
                which makes AFRIWELLGROUP your optimal solution for your on/offshore project work.
                With AFRIWELLGROUP, your valuable assets will be in the hands of the most qualified teams and
                dedicated people, who will preserve and exploit them in the most efficient and safest way`
            },
            p3: {
                title: `MAINTENANCE AND REACTIVATION OF DRILLING RIG (LAND AND PLATFORM)`,
                p1: `We offer personalized solutions with unrivaled efficiency, applying our expertise
                Technical and our engineering solutions to modify, redesign, upgrade and reactivate onshore and offshore drilling platforms. We have the solution for your project.`
            },
            p4: {
                title: `ADVICE FOR RENTAL AND SERVICE HPU 340K & 460K`,
                p1: `Our HPU340K & 460K are a safe and cost-effective alternative to remanufacturing platforms
                conventional`,
                p2: `Cost reduction is directly achieved thanks to the rapid up/down times and
                MOB/De-MOB.`,
                p3: `Can recondition a well, return the well to production and De-Mob before a rig
                large-scale conventional reconditioning can even be installed on an offshore installation
                typical`,
                p4: `OUR WELL TRACTION UNIT PERFORMANCE FOR A 1000m WELL
                Installation (days): 3-5 with Bop test, Installation (days): 2-3, Drilling operations (days): 15-18, Rig moving (days): 1-2 depending on rapid movement of the ship.
                `
            },
            p5: {
                title: `INSPECTION & SURVEY`,
                p1: `Tanks/Separator/Lines & pipes`,
                p2: `Offshore crane according to API 2D`,
                p3: `Drilling structures & Derrick Mast following API4G-CatIII & IV`,
                p4: `Corrosion & coatings`
            },
            p6: {
                title: `MANAGEMENT CONTROL (DRILLING & WORKOVER)`,
                p1: `Budget approach, Budget animation & budget control`,
                p2: `Profitability analysis, profit and control of costs and margins`,
                p3: `Analysis & diagnosis of the financial situation`,
                p4: `Activity & performance management`,
                p5: `Decision-making tools`,
                p6: `Control of activities and risks`,
                p7: `advice & support in business analysis & strategy`
            },
            p7: {
                title: `ASSISTANCE AND ADVICE FOR THE USE OF MICRO-TECHNOLOGY`,
                p1: `Would you like to increase your production in which the permeability of the Virgin reservoir can help you
                give x2, x3 or x4 of your current well production? If so, what would that mean?
                Fewer wells required to achieve your field development plan and production goal.
                Saving 50-75% on development drilling costs?
                For a given number of planned wells: you get 2 to 4 times more production.
                Return on investment (ROI) double, triple or quadruple.`
            }
        }
    },
    products: {
        about: "Our Products",
        home: "Home",
        noclick: "Products",
        list: {
            p0: "Steel pipe",
            p0_1: "COMPLETE SETS OF THERMAL RECOVERY EQUIPMENT",
            p0_2: "ANTI-CORROSION & ANTI-ABRASIZE EQUIPMENT",
            p1: {
                title: "Seamless steel pipe",
                desc: `Carbon steel pipes
                    seamless:
                    Outer diameter: 13.72mm 914.4mm
                    Wall thickness: 1.25mm 50mm
                    Length: 5.8/6/11.8/12m.
                    Stainless steel pipe without
                    welding :
                    Outer diameter: 18.0mm 610mm
                    Wall thickness: 1.6mm 40mm
                    Length: 5.8/6/11.8/12 m`
            },
            p2: {
                title: "Welded steel pipe",
                desc: `Steel pipes for explosive remnants of war:
                Outer diameter: 21.3mm-660mm
                Wall thickness: 1mm to 20mm
                Length: 5.8/6/11.8/12m
                LSAW steel pipe: Outer diameter: 406mm-1626mm
                Wall thickness: 6.0mm to 60mm
                Length: 5.8/6/11.8/12m`
            },
            p3: {
                title: "Tubular products for fields",
                desc: `PLC housing: Outer diameter: 114.3mm-508mm
                Wall thickness: 5.21mm 22.22mm
                Length: R1/R2/R3 API Tubes: Outer Diameter: 40mm-115mm
                Wall thickness: 3.0mm-16mm Length: R1/R2/R3`
            },
            p4: {
                title: "Pipe fittings and flanges",
                desc: `Pipe elbow, pipe elbow, pipe tee, pipe reducer,
                pipe cap, pipe flange, etc. Stainless steel fittings and flanges:
                Pipe elbow, pipe elbow, pipe tee, pipe reducer, pipe cap, pipe flange`
            },
            p5: "CONVENTIONAL DRILLING AND RECOVERY EQUIPMENT",
            p6: "Conventional tubes and casings",
            p7: {
                title: "Tubing & casing connection: Tubing: 1.9 to 4’1/2 casing: 4’1/2 to 5’1/2",
                desc: `Mating materials cover the entire standard range
                High dimensional accuracy. Good performance and anti-seizing`
            },
            p8: {
                title: "Precise Rolling Pipes and Tubes (2 3/8 ~ 4 ½)",
                desc: `Stable and reliable quality. Manufacturing and testing according to the latest version of API 5CT
                Was sold in the United States. Defective detective 100% delivery`
            },
            p9: {
                title: "Case (4 1/2 ~ 13 3/8)",
                desc: `The inner and outer walls of the pipe have excellent high-precision roughness
                (Ra=0.2-0.6). Materials can be changed to meet customer requirements.
                Smooth finish of interior and exterior walls. Able to withstand high pressure`,
            },
            p10: "WELLHEAD AND SHAFT EQUIPMENT X MAS",
            p11: {
                title: "Wellhead and Christmas tree",
                desc: `API 6A standard connections Separate stage for each drilling phase. Working pressure 2000-20000 if -600C to 3900C operating temperature
                Automatic hangers. Reliable quality, easy installation and maintenance. Oil, water, gas, mud
                Psl1 Psl2 Psl3 Psl3g Psl4 PR1PR2.`
            },
            p12: {
                title: "Conventional wellhead and Christmas tree",
                desc: `Standard API 6A flange/clamp connections Easy installation and removal. Reliable sealing performance
                Long life expectancy. Good looking. Diam:2''9/16 WP: 3,000 to 5,000 psi -200C to 800C operating temperature. Connection box size: 5’’1/2. Dimensions(mm):1500x1190; 2268x1740`
            },
            p13: {
                title: "Offshore wellhead and Christmas tree",
                desc: `Combined main valve and swab valve to decrease the height of the
                offshore wellhead and Christmas tree. Hydraulic safety valve in the
                diameter of the main drift to achieve remote shutdown of the wellhead in case of emergency.
                Separate tube head coil. The upper and lower parts are connected by a locking piece of
                insert type. This structure means that the Christmas tree can be adjusted in any direction during
                the installation. Equipped with BIW grommet and adapter flange and bracket installed`
            },
            p14: {
                title: "Fracture Wellhead and Christmas Tree",
                desc: `Standard API 6A flange connection High pressure up to 2000 psi. Higher material class HH
                Long life expectancy. Stable sealing performance. Working pressure 10000-20000 if
                Rated operating temperature. PU Performance rate: PR1PR2 Diam. drift(in):2''9/16;3''1/8;4''1/8 & 7
                `
            },
            p15: {
                title: "Water injection wellhead and Christmas tree",
                desc: `High pressure water injection. Can be used for flushing wells
                Small in size and light. Nickel-based alloy metal spraying technique. superior sealing and anti-corrosion performance
                Install the handwheel in two directions on the block valve. Combination of two valves, cross and flange
                adaptation. significantly reduces the height of the wellhead. Optimal thickness of upper flanges and
                bottom of the tube head. effectively reduces weight and size. Rated pressure (psi): 7250
                Nominal Diameter (in): 2''9/16 Tube Head Drift Diameter (in): 6.3 Connection: Flange
                `
            },
            p16: {
                title: "Gas recovery wellhead and Christmas tree",
                desc: `Composite upper with special structure. Excellent sealing performance and low rotation torque.
                Metal spray valve shutter and seat. The sealing surface is
                very hard and fits with precision. Superior gas tightness performance and
                pressure and corrosion resistant performance. Working pressure from 2000 to 20,000 psi
                -460C to 1200C operating temperature. Diam. drift (in):2’’9/16.3’’1/8
                `
            },
            p17: {
                title: "Electric submersible pump wellhead, Christmas tree",
                desc: `Standard API 6A flange connection. Two types: penetrator and cable penetration.
                Easy installation and removal Reliable sealing performance. Long life expectancy
                Working pressure 2000-20000si -460C to 1210C operating temperature. Diam. drift (in):2’’9/16.3’’1/8
                `
            },
            p18: {
                title: "Wellhead and Christmas Tree Valve",
                desc: `Bidirectional flow. Non-rising/rising stem. Low torque requirement for opening and closing
                API 6A PR1PR2 performance. Quick connect/disconnect steering wheel. Floating plug and valve seat
                Working pressure from 2000 to 20000 psi. Operating temperature from -290C to 1210C
                `
            },
            p19: "PUMPING UNIT-BENEFIT",
            p20: {
                title: "API pumping unit",
                desc: `Strictly apply the API 11E standard. Reducer with API production qualification.
                Complete production tools 5 years warranty. The use of the forge in key parts
                `
            },
            p21: {
                title: "Standard pump unit",
                desc: `Effectively reduce energy consumption. Suitable for heavy oil production. Long, low stroke frequency. Less adjustment of stroke length and stroke ratio
                `
            },
            p22: "SUCKER ROD PUMP-BENEFIT",
            p23: {
                title: "Rod pump",
                desc: `The rod pump can be removed directly from the well. Well inspection and workover is convenient. Reduce the cost of pumping test. Suitable for deeper wells. Barrel Length (ft): 2-34. Piston Length (feet): 2-24. Pump Diameter (in):1’’1/4-2’’1/2
                `
            },
            p24: {
                title: "Anti-wear Sucker Rod Pump Plungers",
                desc: `Anticorrosion. The piston can be made with a monel ended ni-cu pin. Nominal diameter (in):1’’1/16-4’’3/4. Maximum length (ft): 34`
            },
            p25: {
                title: "Tube pump",
                desc: `The structure is simple but strong and durable. The price is low. Has high fluid production in the same diameter tube. Suitable for deeper wells. Barrel Length (ft): 2-34. Piston Length (feet): 2-24. Pump Diameter (in):1’’1/2-5’’`
            },
            p26: {
                title: "Long Piston Sand Control Pump",
                desc: `Adopt long piston and short barrel structure Use sand scraping and sealing device Prevent sand from burying the piston Suitable for oil well whose sand content is less than 3% Connect the tubes ( in.):2''7/8NU & 3''1/2NU Connect Suction Rod(in):3/4 Nominal Diameter(in):1''1/2;1''3/4 & 2' '1/4 Model:20-150THFS/20-175THFS/30-225THFS
                `
            },
            p27: {
                title: "FOPS with long piston and anti-corrosion pump",
                desc: `Unique structure to prevent sand from
                get stuck. Prevent sand burial Has strong anti-corrosion property Suitable for wax-troubled sand wells and oil wells Reduce pump testing times and increase economic efficiency Connect tubing (in):2''7/ 8NU & 3''1/2NU Connect Sucker Rod(in): 3/4 Nominal Diameter(in):1''3/4 & 2''1/2 Model:25-175THFL/25-175THFLF/30 -225THFL/30-225THFLF
                `
            },
            p28: {
                title: "Sand control and centralization Rod pump",
                desc: `Avoid swinging of the pump body and reduce abrasion Prevent sand burial The work is stable and has high efficiency Suitable for oil well with high sand content Connect tube (in):2' '7/8NU Connect Sucker Rod (in): 3/4 Nominal Diameter(in):1''1/2;1''3/4 & 2''1/4 Model:20-125RHBC/25- 150RHBC/25-175RHBC
                 `
            },
            p29: {
                title: "Double piston mechanical valve pump",
                desc: `Unique double piston structure Avoid gas blockage Improve pump effect and extend service life Suitable heavy oil well and gas well, even deviated well Connect tube(in):2''7 /8 & 3"1/2 Connect sucker rod (in): 3/4 & 7/8 Nominal diameter (in):1''3/4, 2''1/4,2”3/4 & 3”1/4 Model:25-175THSJ/25-225THSJ/30-275THSJ/30-325THSJ
                `
            },
            p30: {
                title: "Hydraulic return and anti-corrosion pump",
                desc: `In effect, combined by two different diameter pumps Uses hydraulic feedback theory, reduces sucker rod breakage Long service life Very good anti-corrosion property Connect tube (in):2''7/8 & 3"1/2 Connect Sucker Rod(in): 3/4 and 7/8 Nominal Diameter(in):1''3/4, 1"1/2 2''1/4.1"1 /2,2"3/4 & 1"3/4
                `
            },
            p31: "PRESSURE VESSEL-BENEFIT",
            p32: {
                title: "2 Tank",
                desc: `Efficient vaporization Fuel saving Prevent equipment from paralyzing Max handling capacity/per hour: 5T;6T;8T;10T;15T;20T Import/export mm 110/110; 110/110;160/160;160/160;200/200;200/200`
            },
            p33: {
                title: "3 phase separator",
                desc: `Anti-corrosion performance. High anti-static operation Low cost Protective environment Vertical and horizontal type
                  `
            },
            p34: {
                title: "Natural gas separator",
                desc: `High purifying efficiency. Large dust capacity. Stable operation. Low investment and operating costs. Easy to install and use Vertical and horizontal type`
            },
            p35: {
                title: "Desulfurization tank",
                desc: `High desulfurization efficiency. Easy to install and use. Tank diameter height mm: 300mmx600mm. 1200mmx3720mm. 1800mmx3000mm. Volume(m3):50-4000`
            },
            p36: {
                title: "Crude oil storage tank",
                desc: `Stable performance. High security. Corrosion protection
                 `
            },
            p37: "OIL RECOVERY TOOL",
            p38: {
                title: "Polished shank pliers",
                desc: `The pliers are manufactured in accordance
                 to the requirements of the standards
                 Industrielles-sy/T5029-2013 and
                 API 11B specification.
                 No bumps, effectively reduces
                 damage to the polished stem and
                 packing of the cable gland
                 Available on three models
                 Can be used with two pliers
                 same model and same load
                 to increase the workload
                 `
            },
            p39: {
                title: "Rotator",
                desc: `Can be used in the system
                 rod pump oil production
                 pumping
                 Operating principle: ensures the
                 rotation of the sucker rod at a
                 stable service
                 Effect: reduce eccentric wear and
                 extend the life of the rod
                 pumping and tube
                 Annual production of 5000 pieces
                 Load rotation (lbf): 36,000/13
                 500/13,500
                 `
            },
            p40: {
                title: "Oilfield cable gland",
                desc: `It has a design patent
                utility number ZL2006 2 0085213X
                Adjustment of deviation and anti-eccentric wear: axial self-alignment, radial offset
                Simple maintenance and lifespan
                longer
                The combined type cable gland
                has a more reliable sealing effect
                Auxiliary function: extend the
                life of stuffing box
                and optimize in a certain
                measures the operating status of
                the polished stem
                Anti-leak and anti-theft
                Maximum Diameter (in): 7
                Maximum height (mm): 18
                Working pressure: 3600psi
                Common mode: clamp type and
                coupling
                Maximum alignment and adjustment
                deflected: Axial +/-10°; radial+/-20 mm
                Following polished rod
                diam(in):1”;1”1/8;1”1/4;1”1/2
                            
                `
            },
            p41: {
                title: "Long Term Fine Tuning Polished Rod Trim Box",
                desc: `The sealing parts are
                made up of grades I, II and III
                Practical interview, structure
                compact and easy to use
                Long life of the stuffing box
                Working pressure: 3600psi
                Bottom connection: type of
                pliers and threading
                Dimensions: 190*190*400mm
                More polished rod;
                `
            },
            p42: "Steam boiler for oil field",
            p43: {
                title1: "Benefit",
                desc1: `High drought (above 85%). High thermal efficiency (above 90%). Anti limestone. Remote and unattended monitoring. Energy saving and environmental protection. Wastewater treatment and reinjection
                `,
                title2: "Data sheet",
                desc2: `Nominal evaporation capacity (t/h): Subcritical: 5-50 and super critical: 6.5-40. Working pressure (MPa): 15/17.2/21 and ≤ 26. Steam temperature (°C): 342-370 and 390. Steam dryness (%): 85 and 85. Thermal efficiency (%) :≥90 and ≥90 Combustion method: crude oil gas oil and crude oil gas oil. Transport mode: skid-mounted trailer-mounted and skid-mounted trailer-mounted
                `
            },
            p44: "GAS RECOVERY WELLHEAD AND CHRISTMAS TREE",
            p45: {
                title1: "Benefit",
                desc1: `Resistance to high temperatures. High pressure resistance Reliable performance. Long life expectancy. Made to fit the design
                `,
                title2: "Data sheet",
                desc2: `Nominal pressure (psi): 3000-20000 Nom diam(in):2''1/16;2''9/16;3''1/8;5"
                Production specification level: PSL1 PSL2 PSL3 PSL3GPSL4
                Performance rate: PR1PR2 Material class: AA
                BBCCDDEEFFHH/ Maximum well depth (ft): 16,400
                Maximum working temperature (°C): 390°
                `
            },
            p46: "VACUUM INSULATED TUBES AND HOUSING",
            p47: {
                title1: "Benefit",
                desc1: `Isolated categories D and E. Long life. Both inner tube and outer tube connections are available. Superior insulation properties`,
                title2: "Data sheet",
                desc2: `OD(in) xID(in):
                3”1/2x2”3/8;4”1/2x2”7/8;4”1/2x3”1/2;5”x3”1/2;5”1/2x4”1/2
                7”1/2x5”1/2.
                Steel grade:N80;L80 & P110
                Connection: inner, outer & extra line connections
                Thread:3”1/2USS;4”1/2BC; 4”1/2BC;5BC; 5”1/2BC;7”B
                `
            },
            p48: "VACUUM INSULATED TUBES AND HOUSING",
            p49: {
                title1: "Benefit",
                desc1: `Dual-use steam and injection oil production
                Good performance therefore a better way to avoid
                scaling.
                High production efficiency
                Widely used in steam injection process`,
                title2: "Data sheet",
                desc2: `Model:40-275THZCYT Nominal diam(in):2”3/4
                Stroke length(ft):20 Connecting tubing(in):4”1/2
                Connecting sucker rod (in):7/8
                Pump constant(K):5.518 Largest diam(mm):132.1
                `
            },
            p50: "VACUUM INSULATED TUBES AND HOUSING",
            p51: {
                title1: "Benefit",
                desc1: `Accurate measurement of oil, water and gas
                 User-friendly interface
                 Easy operation
                 Modular design incorporating the latest
                 systems technologies
                 measure
                 Remote data transmission,
                 allowing data acquisition in time
                 real and digitalization of the oil field`,
                title2: "Data sheet",
                desc2: `Universal gas & liquid automatic metering device
                 Metering range: gas range(nm3/d):0-1000
                  &Liquid range(m3/d):0-50
                 Metering error: Air current(m3/d):+/-5.0
                  & liquid current (m3/d):+/-3.0
                 Nominal Pressure(psi):350
                 Working temp(°C):5-80
                 Power supply AC480V60H
                 `
            },
            p52: "AUTOMATIC OILFIELD WORKOVER RIGS",
            p53: {
                title1: "Benefit",
                desc1: `Automated operation at one
                 single button with high degree
                 automation
                 Reduces labor costs
                 Reduce work intensity
                 Operation is safe and reliable`,
                title2: "Data sheet",
                desc2: `Minor work over automatic system
                 Ambient temp(°C):-20-45
                 Ambient humidity(°C):≤90%+20°C
                 Tripping speeds(s/joint):120
                 Rated pressure of hydraulic system(psi):2300
                 Electricity demand 380V(+/-10%):80Kw
                 Air pressure(psi):≥116
                 Loading efficiency(joints/hour):30
                 Loading capacity(lb):Rated capacity 90,000 max capacity
                 15100 Loading power (kw):37 Applied tubing(in):2"3/8;2"7/8;3"1/2 NU tubing
                 Operating staff(persons):3`
            },

            p54: "DRILLING & COMPLETION TECHNOLOGY AND PRODUCTS",
            p55: {
                title: "Liner Hanger",
                title1: "Benefit",
                desc1: `Reinforced sealing performance between the casing
                 upper and exhaust pipe with excellent
                 results which effectively prevent corrosion of the
                 C02 gas.
                 Potential sleeve cost savings for
                 well of praise of exploration and evaluation.
                 Quick and easy to seal open holes, old
                 wells or to deepen the cementation
                 A flexible means of completion and operation
                 safer cementing
                 Seals damaged sections of the upper crankcase
                 (corrosion, fracture)`,
                title2: "Data sheet",
                desc2: `Model:SSX
                 Rated capacity (lbs):352,000
                 Seat pressure (psi): 1000-1700
                 Support Sleeve Load Capacity (lbs): 35
                 200
                 Sealing capacity (psi): 3600
                 `,
            },
            p56: {
                title: "Variable centralizer oil & gas",
                title1: "Benefit",
                desc1: `Improves support structure
                 centered on the spine and better
                 zonal insulation
                 Maintains flow zones
                 the annular space and the density of
                 circulation equivalent to a value
                 appropriate neither too high nor too low.
                 Do not stab or scratch the walls
                 of Wells.
                 Strong bearing capacity, supporting
                 effectively the wall of the well`,
                title2: "Data sheet",
                desc2: `Model: FZQ140-185 and FZQ178-205
                 Maximum diameter (mm):185 & 205
                 Mini bore (mm): 121 and 157
                 Maximum expansion diameter (mm):
                 335 and 380
                 Open pressure (psi): 2000
                 `,
            },
            p57: {
                title: "Packer oil & gas",
                title1: "Benefit",
                desc1: `Improved oil recovery and production
                Improving Well Efficiency Through Staging
                and production training
                Helps complete the multi-level segment block
                when the water comes out later.
                Prevent oil, gas and water from flowing between
                layers
                Improving the efficiency of oil and gas fracturing
                unconventional gases`,
                title2: "Data sheet",
                desc2: `Model: Y341-115A; Y221-115 and Y344-150
                Type: compression
                Joint type: hydraulic; rotate the pump and
                hydraulics
                Working pressure: 2000;3000 & 10,000
                Maximum temperature: 120; 120 and 150
                Connection thread: 2"7/8EU
                Use: water injection, oil recovery and
                water injection or oil recovery
                `,
            },
            p58: "HDPE/EXPE COATING TUBE",
            p59: {
                title1: "Benefit",
                desc1: `Less stem wear
                Superior resistance to chemicals and
                corrosion.
                Extended repair free time 4.7 times
                Abrasion resistant.
                Working temperature up to 100°C.
                Defective detective due to inspection of
                100% quality before delivery.`,
                title2: "Data sheet",
                desc2: `Size:2''7/8NU/2''7/8EU//3''1/2NU/3''1/2EU//4''1/2NU/4''1/2EU
                ID (mm): 62 and 54/76 and 67/100.54 and 89
                Coupling (OD: mm): 88.9 and 93.17/107.95 and
                114.30/132.08 and 141.30
                Liner tube coupling (mm):
                151&158/166&171/182&186
                Flared length (mm): 7-9&8-10/8-10&8-10/10-12&10-12
                Maximum weight (°C): 100
                `,
            },
            p60: "ANTI CORROSION TUBE EPOXY RESIN COATING",
            p61: {
                title1: "Benefit",
                desc1: `High mechanical strength, excellent toughness
                The coating is non-toxic and non-polluting
                Excellent physical and mechanical performance
                Good heat resistance under the temperature of 150°C
                Smooth surface to prevent dirt from accumulating`,
                title2: "Data sheet",
                desc2: `Shock resistance: ≥11J and SY/T0442
                Abrasion resistance
                (1000g;1000rotation):≤20mg & GB/T1768
                Adhesion strength: grade 1-3 and SY/T0442
                Number of hole pins≤1/m2 and SY/T0063
                Coating thickness: 250-300πm and
                SY/T0066
                `,
            },
            p62: "COATED SUCTION ROD",
            p63: {
                title1: "Benefit",
                desc1: `Based on Weatherford technology
                 New and used rod all available,
                 Could you reduce the unit load by
                 pumping Energy saving
                 Good anti-scaling and anti-corrosion performances`,
                title2: "Data sheet",
                desc2: `Coated material: nylon composite
                 Coated thickness (mm): 2 (according to the requirement of
                 customer)
                 Length of sprayer law (mm): 320
                 Thickness of sprayer law (mm): 0.2
                 `,
            },
            p64: "PLATED TUNGSTEN ALLOY TUBES",
            p65: {
                title1: "Benefit",
                desc1: `Large combination capacity
                 High temperature resistance
                 No flakes or pollution
                 Advanced nickel-based technology`,
                title2: "Data sheet",
                desc2: `Plating hardness: 50-550HV (plated condition)
                 940-1100HV (heat treatment)
                 Wear resistance: 2 to 3 times higher than chrome plating
                 Corrosion resistance: resistance to aggressive services
                 such as H2S, C02, NACL, HCL.
                 Current efficiency: 80% to 85%
                 Deposition rate: 35µm-45µm/h
                 Adhesion: 8 kg/mm2, plating without dropping at high temperature
                 and pressure
                 `,
            },
            p66: "ANTI H2S WELLHEAD AND CHRISTMAS TREE",
            p67: {
                title1: "Benefit",
                desc1: `Safety valve in the diameter of the
                 main drift to get the head to stop
                 wells in case of emergency
                 All pressure parts are
                 Made from premium alloy
                 forged through heat treatment
                 suitable and an NDT, they have a good
                 sulphide resistance capacity.
                 Metal-to-metal joint tube support with
                 a long lifespan
                 Rising stem valve with tail stem
                 balanced to prevent the valve from remaining
                 stuck under high pressure
                 Side outlets with VR thread
                 allow the replacement of the gate valve
                 under pressure
                 `,
                title2: "Data sheet",
                desc2: `Working pressure (psi): 10 M, 15 M and 20 M
                 Operating temperature (°C): -46°C-120°C
                 Material:DD,EE,FF
                 Production specification level: PSL2, PSL3, PSL3G,
                 PSL4
                 Performance rate: PR1PR2
                 Fin Diameter (in):2"9/16;3"1/8
                 `,
            },
            p68: "ANTI-CORROSIVE PUMP ROD PUMP",
            p69: {
                title1: "Benefit",
                desc1: `Anti-wear
                 Anti corrosion
                 Chrome and nickel-phosphorus plated
                 For nickel and phosphorus plating, we
                 can make identifications, diameters
                 exteriors and threads for brass barrels.
                 `,
                title2: "Data sheet",
                desc2: `Type: thick wall rod pump
                 Thin wall rod pump: 1''1/4-2"1/2
                 Pump tube bore: 1"3/4-3"3/4
                 Piston Length (feet): 2-24
                 Barrel Length (ft): 2-34
                 Barrel Material:
                 A4: chrome plate on NI/CU alloy
                 A5: chrome plate on brass
                 B4: heavy chrome plate on NI/CU alloy
                 A5: heavy chrome plate on brass
                 Material Plunger:
                 D1-D3: metal spray with metal ends
                 NI/CU alloy
                 E1-E3: Sprayed metal with pin ends
                 nickel plated and the full length of the inside diameter
                 F1-F3: Sprayed metal with pin ends
                 nickel plated and the full length of the inside diameter
                 Corrosion medium: H2s; C02; saline water,
                 oxygen
                 `,
            },
        }
    }
}